import { initializeApp } from 'firebase/app';
import { useState } from 'react';
import { useAuthFlow } from './useAuthFlow';
import {
  createUserWithEmailAndPassword,
  getAuth,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import { clearToken, getToken, setToken } from '@app/signals/token';
import { OrderbookService } from '@api/orderbook_api/v1/orderbook.pb';
import { InternalApi } from '@api/marketplace_admin_api/v1/internal_api.pb';
import { logInfo } from '@app/utils/logger';
import { getRequestOpts } from '@app/utils/requestOpts';
import { AdminApi } from '@api/marketplace_admin_api/v1/marketplace_api.pb';

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
};

export type LoginInfo = {
  email: string;
  password: string;
};

export type SignUpInfo = LoginInfo & {
  captchaToken?: string;
  apiCode?: string;
};

export function useAuth() {
  const [error, setError] = useState('');
  const [email, setEmail] = useState('');
  const { transitionToStep } = useAuthFlow();

  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);

  const signUp = async (signUpInfo?: SignUpInfo) => {
    if (!signUpInfo) return;
    clearToken();

    const { email, password, captchaToken, apiCode } = signUpInfo;
    setEmail(email);
    const userCredentials = await createUserWithEmailAndPassword(
      auth,
      email,
      password,
    );
    logInfo('apiCode', apiCode);
    await AdminApi.VerifyEmail({ token: apiCode }, getRequestOpts());
    const firebaseToken = await userCredentials.user.getIdToken();
    // const response = await signInWithFirebase(
    //   getFirebaseToken() as string,
    //   acceptedTermsOfService,
    //   captchaToken,
    //   window.location.href,
    // );

    // const payload = (await response.json()) as AuthResponse;
    // await handleSigninResponse(response, payload);
    // if (payload.status === 'AuthStatusRequiresCreateOtp') {
    //   transitionToStep('marketing-emails');
    // }
  };

  const signIn = async (loginInfo?: LoginInfo) => {
    if (!loginInfo) return;
    clearToken();

    const { email, password } = loginInfo;
    const userCredentials = await signInWithEmailAndPassword(
      auth,
      email,
      password,
    );
    const idToken = await userCredentials.user.getIdToken();
    const response = await AdminApi.Auth({ idToken }, getRequestOpts());

    if (response.token) {
      logInfo('setting token', response.token);
      setToken(response.token);
    }
  };

  return {
    error,
    signUp,
    signIn,
  };
}
