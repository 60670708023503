import { AUTH_TOKEN_KEY } from '@app/signals/token';
import { logInfo } from './logger';
import { InitReq } from '@api';

export const getRequestOpts = () => {
  const pathPrefix = process.env.NEXT_PUBLIC_ORDERBOOK_URL;
  logInfo('pathPrefix', pathPrefix);
  if (typeof window === 'undefined') {
    return {
      pathPrefix,
    };
  }

  const token = localStorage.getItem(AUTH_TOKEN_KEY);
  if (!token) {
    return {
      pathPrefix,
    };
  }
  return {
    pathPrefix,
    headers: {
      authorization: `Bearer ${token}`,
    },
    //TODO: exclude in prod
    credentials: 'same-origin',
  } as InitReq;
};
