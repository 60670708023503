// import { reportToSentry } from '@utils/analytics/events';

export const LOG_LEVELS = {
  CRITICAL: 0,
  ERROR: 1,
  WARN: 2,
  INFO: 3,
  DEBUG: 4,
} as const;

export type LOG_LEVEL = keyof typeof LOG_LEVELS;
type LOG_LEVEL_VALUES = (typeof LOG_LEVELS)[LOG_LEVEL];

type InstallLoggerProps = {
  loggingLevel: LOG_LEVEL;
  reportingLevel: LOG_LEVEL;
};

let DISPLAY_LOG_LEVEL: LOG_LEVEL_VALUES = LOG_LEVELS['INFO'];
let REPORT_LOG_LEVEL: LOG_LEVEL_VALUES = LOG_LEVELS['CRITICAL'];

export function installLogger({
  loggingLevel,
  reportingLevel,
}: InstallLoggerProps) {
  if (LOG_LEVELS[loggingLevel] === undefined) {
    throw new Error(`invalid logging level: ${loggingLevel}`);
  }

  if (LOG_LEVELS[reportingLevel] === undefined) {
    throw new Error(`invalid logging level: ${reportingLevel}`);
  }

  DISPLAY_LOG_LEVEL = LOG_LEVELS[loggingLevel] ?? LOG_LEVELS['INFO'];
  REPORT_LOG_LEVEL = LOG_LEVELS[reportingLevel] ?? LOG_LEVELS['CRITICAL'];
}

export function setLogLevel(level: LOG_LEVEL) {
  console.log('[Dev] ℹ️ SETTING LOG LEVEL', level);
  DISPLAY_LOG_LEVEL = LOG_LEVELS[level];
}

const doLog =
  (log: 'info' | 'warn' | 'error', level: LOG_LEVEL, icon: string) =>
  (...props: unknown[]) => {
    if (DISPLAY_LOG_LEVEL >= LOG_LEVELS[level]) {
      console[log](`[Dev] ${icon}`, ...props);
    }

    if (REPORT_LOG_LEVEL >= LOG_LEVELS[level]) {
      // reportToSentry(new Error(JSON.stringify(props)));
    }
  };

export const logCritical = doLog('error', 'CRITICAL', '🆘');
export const logError = doLog('error', 'ERROR', '🚨');
export const logWarn = doLog('warn', 'WARN', '⚠️');
export const logInfo = doLog('info', 'INFO', '🟢');
export const logDebug = doLog('info', 'DEBUG', '🔎');
