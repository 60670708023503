import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react';

type AuthStep =
  | 'none'
  | 'login'
  | 'signup'
  | 'verify-email'
  | 'email-verified'
  | 'create-otp'
  | 'check-otp'
  | 'backup-codes'
  | 'marketing-emails'
  | 'terms-and-privacy'
  | 'forgot'
  | 'reset-password'
  | 'reset-2fa'
  | 'enter-backup-code';

type AuthFlowContextType = {
  currentStep: AuthStep;
  transitionToStep: (newState: AuthStep) => void;
  oobCode: string;
  setOobCode: (code: string) => void;
  isMfaReset: boolean;
};

const AuthFlowContext = createContext<AuthFlowContextType>({
  currentStep: 'none',
  transitionToStep: () => {},
  oobCode: '',
  setOobCode: () => {},
  isMfaReset: false,
});

export const AuthFlowProvider = ({ children }: PropsWithChildren) => {
  const [currentStep, setStep] = useState<AuthStep>('none');
  const [oobCode, setOobCode] = useState<string>('');
  const [isMfaReset, setIsMfaReset] = useState<boolean>(false);

  const transitionToStep = useCallback((newState: AuthStep): void => {
    setStep((state) => {
      if (state !== newState) {
        if (state === 'none') {
          setIsMfaReset(false);
        }
        if (state === 'reset-2fa') {
          setIsMfaReset(true);
        }
        return newState;
      }
      return state;
    });
  }, []);

  return (
    <AuthFlowContext.Provider
      value={{ currentStep, transitionToStep, oobCode, setOobCode, isMfaReset }}
    >
      {children}
    </AuthFlowContext.Provider>
  );
};

export const useAuthFlow = (): AuthFlowContextType => {
  const context = useContext(AuthFlowContext);
  return context;
};
