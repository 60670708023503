'use client';
import classNames from 'classnames';
import { Suspense, useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { LoginInfo, useAuth } from '@app/context/useAuth';
import { Button, ErrorText, Label, Modal, classes } from '@app/components';
import { useSearchParams, useRouter } from 'next/navigation';
import { sToken } from '@app/signals/token';
import { useSignals } from '@preact/signals-react/runtime';

type FormProps = LoginInfo;

function LoginPageInternal() {
  useSignals();
  const router = useRouter();
  const { signIn } = useAuth();
  const searchParams = useSearchParams();
  const email = searchParams.get('email') || '';
  const [loginError, setLoginError] = useState<string | null>(null);

  useEffect(() => {
    sToken.value && router.push('/selectMarketplace');
  }, [router]);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormProps>({
    defaultValues: { email, password: '' },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  register('email', {
    required: 'Email is required',
    pattern: {
      value: /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})*$/,
      message: 'Invalid email address',
    },
  });

  register('password', {
    required: 'Password is required',
  });

  const onSubmit = useCallback(
    (data: FormProps) => {
      signIn(data)
        .then(() => {
          console.log('logged in');
          router.push('/selectMarketplace');
        })
        .catch((e) => {
          console.error('signin error', e);
          setLoginError('Invalid email or password');
        });
    },
    [router, signIn],
  );

  return (
    <main>
      <Modal open>
        <form className='flex flex-col gap-2' onSubmit={handleSubmit(onSubmit)}>
          <h1>Login</h1>
          <div className='relative'>
            <Label>Email</Label>
            <Controller
              name='email'
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <input
                  autoComplete='email'
                  className={classes.input}
                  type='text'
                  placeholder={'Enter email address'}
                  onChange={onChange as any}
                  onBlur={onBlur}
                  value={value}
                />
              )}
            />
            {errors.email && <ErrorText error={errors.email.message} />}
          </div>
          <div className='relative'>
            <Label>Password</Label>
            <Controller
              name='password'
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <input
                  autoComplete='new-password'
                  type='password'
                  placeholder={'Enter password'}
                  onChange={onChange as any}
                  onBlur={onBlur}
                  value={value}
                  className={classNames(classes.input, 'pr-[4rem]')}
                />
              )}
            />
            {errors.password && <ErrorText error={errors.password.message} />}
          </div>
          <Button type='submit'>Log in</Button>
          {loginError && <ErrorText error={loginError} />}
        </form>
      </Modal>
    </main>
  );
}

export default function LoginPage() {
  return (
    <Suspense>
      <LoginPageInternal />
    </Suspense>
  );
}
