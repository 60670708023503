/* eslint-disable */
// @ts-nocheck
/*
 * This file is a generated Typescript file for GRPC Gateway, DO NOT MODIFY
 */

import * as CommonV1Common from "../../common/v1/common.pb";
import * as fm from "../../fetch.pb";
import * as Orderbook_apiV1Types from "../../orderbook_api/v1/types.pb";
import * as Marketplace_admin_apiV1Types from "./types.pb";
export type VerifyEmailRequest = {
  token?: string;
};

export type ResetPasswordRequest = {};

export type GetMarketplaceConfigRequest = {
  id?: string;
};

export type GetMarketplaceConfigsResponse = {
  configs?: Orderbook_apiV1Types.MarketplaceConfig[];
};

export class AdminApi {
  static Auth(
    req: Marketplace_admin_apiV1Types.AuthRequest,
    initReq?: fm.InitReq,
  ): Promise<Marketplace_admin_apiV1Types.AuthResponse> {
    return fm.fetchReq<
      Marketplace_admin_apiV1Types.AuthRequest,
      Marketplace_admin_apiV1Types.AuthResponse
    >(`/v1/auth/signin`, {
      ...initReq,
      method: "POST",
      body: JSON.stringify(req, fm.replacer),
    });
  }
  static VerifyEmail(
    req: VerifyEmailRequest,
    initReq?: fm.InitReq,
  ): Promise<CommonV1Common.Empty> {
    return fm.fetchReq<VerifyEmailRequest, CommonV1Common.Empty>(
      `/v1/auth/verify-email`,
      { ...initReq, method: "POST", body: JSON.stringify(req, fm.replacer) },
    );
  }
  static ResetPassword(
    req: ResetPasswordRequest,
    initReq?: fm.InitReq,
  ): Promise<CommonV1Common.Empty> {
    return fm.fetchReq<ResetPasswordRequest, CommonV1Common.Empty>(
      `/v1/auth/reset-password`,
      { ...initReq, method: "POST", body: JSON.stringify(req, fm.replacer) },
    );
  }
  static GetMarketplaceConfigs(
    req: CommonV1Common.Empty,
    initReq?: fm.InitReq,
  ): Promise<GetMarketplaceConfigsResponse> {
    return fm.fetchReq<CommonV1Common.Empty, GetMarketplaceConfigsResponse>(
      `/v1/configs?${fm.renderURLSearchParams(req, [])}`,
      { ...initReq, method: "GET" },
    );
  }
  static GetMarketplaceConfig(
    req: GetMarketplaceConfigRequest,
    initReq?: fm.InitReq,
  ): Promise<Orderbook_apiV1Types.MarketplaceConfig> {
    return fm.fetchReq<
      GetMarketplaceConfigRequest,
      Orderbook_apiV1Types.MarketplaceConfig
    >(`/v1/config?${fm.renderURLSearchParams(req, [])}`, {
      ...initReq,
      method: "GET",
    });
  }
  static SetMarketplaceConfig(
    req: Orderbook_apiV1Types.MarketplaceConfig,
    initReq?: fm.InitReq,
  ): Promise<Orderbook_apiV1Types.MarketplaceConfig> {
    return fm.fetchReq<
      Orderbook_apiV1Types.MarketplaceConfig,
      Orderbook_apiV1Types.MarketplaceConfig
    >(`/v1/config/${req["id"]}`, {
      ...initReq,
      method: "PUT",
      body: JSON.stringify(req, fm.replacer),
    });
  }
  static Me(
    req: CommonV1Common.Empty,
    initReq?: fm.InitReq,
  ): Promise<Marketplace_admin_apiV1Types.User> {
    return fm.fetchReq<CommonV1Common.Empty, Marketplace_admin_apiV1Types.User>(
      `/v1/me?${fm.renderURLSearchParams(req, [])}`,
      { ...initReq, method: "GET" },
    );
  }
}
